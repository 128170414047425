<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>&nbsp;
                    <button id="add" class="btn btn-primary"                   
                    @click="addDepositClick()">
                        <i class="fa fa-plus"></i> Add Deposit
                    </button>&nbsp;
                    <button type="button" class="btn btn-secondary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button>
                </div>

                <invoice-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <invoice-create-form ref="InvoiceCreateForm" :reload="reload"/>
                <invoice-status-form ref="InvoiceStatusForm" :reload="reload"/>
                <invoice-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import invoiceGrid from './Grid/InvoiceGrid.vue';
import invoiceCreateForm from './Component/InvoiceCreateForm.vue';
import invoiceServices from './Script/InvoiceServices';
import invoiceStatusForm from './Component/InvoiceStatusForm.vue';
import invoicePDF from '../../../assets/template/InvoicePdfTemplate.vue';
import moment from 'moment';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Invoice',
    components: {
        'invoice-grid': invoiceGrid,
        'invoice-create-form': invoiceCreateForm,
        'invoice-status-form': invoiceStatusForm,
        'invoice-pdf': invoicePDF,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Invoice');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.InvoiceCreateForm.addClick();
        },
        addDepositClick(){
            this.$router.push({ name: 'Invoice Form', params: { formtype:'Add', id:' ', view:'new', type:'Deposit', poid:' ' } });
        },
        editClick(id, view, type){
            var typeText = type == 1 ? 'Invoice' : 'Deposit';
            const routeData = this.$router.resolve({ name: 'Invoice Form', params: { formtype:'Edit', id:id, view:view, type:typeText, poid:' ' } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await invoiceServices.getDetailInvoice(id);
            
            var deliveryOrderId = null;
            if (data.invoice_type == "1") {
                var deliveryOrderData = await invoiceServices.deliveryOrderArrayToString(data.transaction_invoice_delivery_order);
                deliveryOrderId = deliveryOrderData.delivery_order_id
            }

            var transactionInvoiceDetail = this.$globalfunc.objectToArrayConverter(data.transaction_invoice_detail, 'Transaction-Invoice-InvoiceItemDetail');

            const invoiceData = {
                invoice_number: null,
                invoice_type: data.invoice_type,
                invoice_date: moment(data.invoice_date).format("YYYY-MM-DD"),
                due_date: moment(data.due_date).format("YYYY-MM-DD"),
                status: "Delete",
                purchase_order_id: data.purchase_order_id,
                delivery_order_id: deliveryOrderId,
                customer_id: data.customer_id,
                tax_invoice: data.tax_invoice,
                tax_percentage: data.tax_percentage,
                tax_invoice_deposit: data.tax_invoice_deposit,
                total: data.total,
                total_deposit: data.total_deposit,
                notes: data.notes,
                transaction_invoice_detail: transactionInvoiceDetail
            };

            const variables = {
                id : parseInt(id),
                data : invoiceData
            };
            
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    invoiceServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.InvoiceStatusForm.statusClick(data);
        },
        pdfClick(id, type) {
            var typeText = type == 1 ? 'Invoice' : 'Deposit';
            
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.pdf.generatePDF(id, typeText);
            //     }else if (result.isDenied) {
            const routeData = this.$router.resolve({name: 'Invoice Html Template', params: { id:id, type:typeText }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.$refs.grid.changeStatus(['New','Sent']);
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus(['New']);
                });
                $("#btnSent").on('click', function() {
                    vue.$refs.grid.changeStatus(['Sent']);
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus(['Delete']);
                });
            })
        },
        async exportExcel () {
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'TaxInvoice_' + dateNow + '.xlsx';
            var data = await this.$refs.grid.excel();
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = invoiceServices.invoiceExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
    }
}
</script>

<style scoped>
</style>