<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Finance Approver</h2>
            </CCardHeader>
            <CCardBody>
                <div class="btn-group w-100">
                    <button id="btnAll" style="width:12%" class="btn btn-secondary btn-All">All</button>&nbsp;
                    <button id="btnClear" style="width:12%" class="btn btn-secondary btn-All">Clear</button>&nbsp;
                    <button id="btnUnclear" style="width:12%" class="btn btn-secondary btn-All">Unclear</button>&nbsp;
                </div><hr>

                <finance-approver-grid ref="grid" :key="gridReload" :viewClick="viewClick" :approveClick="approveClick" :pdfClick="pdfClick"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import financeApproverGrid from '../FinanceApprover/Grid/FinanceApproverGrid.vue';
import financeApproverServices from './Script/FinanceApproverScript';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'FinanceApprover',
    components: {
        'finance-approver-grid': financeApproverGrid,
    },
    async mounted () {
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        viewClick(id, view){
            this.$router.push({ name: 'Purchase Order Form', params: { formtype:'Edit', id:id, view:view } });
        },
        async approveClick(id){
            const variables = {
                id : parseInt(id)
            };

            this.$swal(sweet_alert.approve).then((result) => {
                if (result.isConfirmed == true) {
                    financeApproverServices.approveQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successApprove, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        pdfClick(id) {
            this.$swal({
                title: "Cetak Proforma Invoice",
                showCancelButton: true,
                html:`
                    <div class="row pb-3" style="width:100%; text-align:left">
                        <div class="col-4">
                            <label class="form-label">Nomor Invoice</label>
                        </div>
                        <div class="col-1">
                            <label class="form-label">:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" id="invoiceNumber" class="form-control">
                        </div>
                    </div>
                    <div class="row" style="width:100%; text-align:left">
                        <div class="col-4">
                            <label class="form-label">Faktur Pajak</label>
                        </div>
                        <div class="col-1">
                            <label class="form-label">:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" id="npwp" class="form-control">
                        </div>
                    </div>`,
                focusConfirm: false,
                confirmButtonText: 'Print Html',
                confirmButtonColor: '#321fdb',
                preConfirm: function () {
                    return new Promise(function (resolve) {
                        resolve({
                            invoice_number : $('#invoiceNumber').val(),
                            npwp : $('#npwp').val(),
                        })
                    })  
                },
            }).then((result) => {
                if (result.isConfirmed == true) {
                    var invoiceNumber = result.value.invoice_number == "" || null ? " " : result.value.invoice_number;
                    var npwp = result.value.npwp == "" || null ? " " : result.value.npwp;
                    const routeData = this.$router.resolve({name: 'Finance Approver Html Template', params: { id:id, invoicenumber:invoiceNumber, npwp:npwp }});
                    window.open(routeData.href, '_blank');
                }
            });
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnClear").on('click', function() {
                    vue.$refs.grid.changeStatus(true, false);
                });
                $("#btnUnclear").on('click', function() {
                    vue.$refs.grid.changeStatus(false, true);
                });
            })
        },
    }
}
</script>

<style scoped>
</style>