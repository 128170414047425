<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
  </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import financeApproverServices from '../Script/FinanceApproverScript';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'FinanceApproverGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['viewClick', 'approveClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging,
                                payment : 'Cash',
                                approve : false,
                                not_approve : false,
                                financeApprover : true
                            }
                            return { 
                                query : financeApproverServices.readFinanceApproverQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPurchaseOrder.transaction_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: { type: "date" },
                            total: {type: "number" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton}, 
                { field: "payment_status", title: "Status", width: 90, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "sp_number", title: "No. SP", width: 130, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "purchase_order_number", title: "No. PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0: yyyy-MM-dd}"  },
                { field: "total", title: "Total", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(Math.round(total), 'n') #" },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 140, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"},
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var approveClick = this.$props.approveClick;
        var viewClick = this.$props.viewClick;
        var pdfClick = this.$props.pdfClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            approveClick(dataItem.purchase_order_id);
        });

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            viewClick(dataItem.purchase_order_id, true);
        });

        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.purchase_order_id)
        });
    },
    methods: {
        columnButton(){
            var customButton = '';
            return this.$globalfunc.gridActionApproveButton("Finance Approver", customButton)
        },
        changeStatus(clear, unclear){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging,
                                payment : 'Cash',
                                approve : clear,
                                not_approve : unclear,
                                financeApprover : true
                            }
                            return { 
                                query : financeApproverServices.readFinanceApproverQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPurchaseOrder.transaction_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: { type: "date" },
                            total: {type: "number" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    }
}
</script>