import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class FinanceApproverService {
    readFinanceApproverQuery(){
        var query = `query($paging:ServerPaging, $status:String, $payment:String, $not_approve:Boolean, $approve:Boolean, $check:Checker, $financeApprover:Boolean){
            GetTransactionPurchaseOrder(Paging:$paging, Status:$status, PaymentMethod:$payment, IsNotApproved:$not_approve, IsApproved:$approve, 
                Check:$check, FinanceApprover:$financeApprover){
                    transaction_purchase_order{
                        purchase_order_id
                        status
                        purchase_order_number
                        purchase_order_date
                        created_at
                        sp_number
                        customer_name
                        total
                        project_type
                        project_name
                        payment_method
                        notes
                        attachment
                        created_by
                        sales_name
                        approved_by
                        payment_status
                    }
                    total
                }
            }`;
        return query;
    }

    async approveQuery(variables){
        var query = gql`mutation($id:Int!){
            TransactionApproveFinance(PurchaseOrderId:$id)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    itemInvoicePDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { quantity : data[i].quantity,
                            uom : data[i].uom,
                            product_code : data[i].product_code,
                            item_name : data[i].item_name,
                            price : data[i].vat_per_unit,
                            total : data[i].vat,
                            price_string : kendo.toString(Math.round(data[i].vat_per_unit), "n0"),
                            total_string : kendo.toString(Math.round(data[i].vat), "n0") }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    getAllTotalInvoice(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].total; 
            }
        }
        return totalPrice;
    }
}

export default new FinanceApproverService();