<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import invoiceServices from '../Script/InvoiceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'InvoiceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {                                
                                var swapfield = [
                                    { field: "status", new_field: "\"transaction_invoice\".\"status\"" },
                                    { field: "created_at", new_field: "\"transaction_invoice\".\"created_at\"" },
                                    { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                    { field: "total", new_field: "\"transaction_invoice\".\"total\"" },
                                    { field: "notes", new_field: "\"transaction_invoice\".\"notes\"" },
                                ];
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                                }
                                const variables = {
                                    paging : paging,
                                    status : ['New','Sent']
                                }
                                return { 
                                    query: invoiceServices.readInvoiceQuery(),
                                    variables: variables
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTransactionInvoice.transaction_invoice == null){
                                return [];
                            }else{
                                return response.data.GetTransactionInvoice.transaction_invoice;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTransactionInvoice.transaction_invoice == null){
                                return 0;
                            }else{
                                return response.data.GetTransactionInvoice.total;
                            }
                        },
                        model: {
                            fields: {
                                invoice_type: {type: "dropdown" },
                                total: {type: "number" },
                                invoice_date: {type: "date"},
                                due_date: {type: "date"},
                                created_at: {type: "date"}
                            }
                        }
                    },
                    sort: { field: "created_at", dir: "desc" }
                }),
            columns:  [
                { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes: {style: "text-align:center;"} },
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "invoice_type", title: "Tipe Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    filterable: {   ui: this.invoiceTypeFilter },
                    template: "#= invoice_type == 1 ? 'Invoice' : 'Deposit' #" },
                { field: "invoice_number", title: "No. Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoice_date", title: "Tanggal Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "due_date", title: "Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    template: "#= kendo.toString(kendo.parseDate(due_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "sp_number", title: "Nomor SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "total", title: "Total", format: "{0:N2}", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString(total, 'n2') #"
                },
                { field: "tax_invoice", title: "No. Faktur Pajak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter: "false",
            hf_ID_Filter: "",
            invoiceTypeFilterData: [
                {value: 1, label: 'Invoice'},
                {value: 2, label: 'Deposit'}
            ],
            statusFilterData: ['New', 'Sent'],
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var pdfClick = this.$props.pdfClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.invoice_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.invoice_id, false, dataItem.invoice_type);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.invoice_id, true, dataItem.invoice_type);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            const invoiceData = {
                invoice_id : dataItem.invoice_id,
                invoice_number : dataItem.invoice_number,
                status : dataItem.status
            }
            
            statusClick(invoiceData);
        })

        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.invoice_id, dataItem.invoice_type)
        })

        GridElement.on("change", "#masterCheckBoxFilter", function (e) {
            e.preventDefault();
            var state= this.checked;
            var data = grid._data;
            
            for (var i = 0; i < data.length; i++) {
                var datarow = data[i]
                var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                
                if (elementRow != null) {
                    if (state) {
                    elementRow.checked = true;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                    }
                    else
                    {
                    elementRow.checked = false;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                    }
                }
             }
             var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
              if (state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="true";
                }
                else if (!state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="false";
                    vueComponent.hf_ID_Filter="";
                }
                else if (state && hf_CheckAll_Filter == "false") {
                    vueComponent.hf_CheckAll_Filter= "true";
                    vueComponent.hf_ID_Filter="";
                }
                else if (!state)
                    vueComponent.hf_CheckAll_Filter="false";
                else
                    vueComponent.hf_CheckAll_Filter= "true";
        });

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.invoice_id.toString();
                       
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        invoiceTypeFilter: function(e) {
            e.kendoDropDownList({
                autoBind: false,
                dataSource: this.invoiceTypeFilterData,
                dataTextField: "label",
                dataValueField: "value",
                optionLabel: "Pilih Tipe Invoice"
            });
        },
        headerTemplate() {
            return `<input type="checkbox" id="masterCheckBoxFilter"" />`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionWithStatusChangesButton("Invoice", customButton);
        },
        detailInit: async function (e) {
            var data = await invoiceServices.getDetailInvoice(e.data.invoice_id);
            
            var html = invoiceServices.invoiceDetailTemplate(data);
            var invoiceData = invoiceServices.itemInvoicePDF(data.transaction_invoice_detail);

            $('<div/>').appendTo(e.detailCell).html(html);
            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Daftar Barang</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(invoiceData.grid_data);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    aggregate: [
                        { field: "price_discount", aggregate: "sum" },
                        { field: "tax_amount", aggregate: "sum" },
                        { field: "vat", aggregate: "sum" }
                    ]
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'base_quantity', title: "Jumlah", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'base_uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true},
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                    { field: 'price_discount_per_unit', title: "Harga", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}", editable: false, nullable: true, attributes: { "class": "k-text-right" }, 
                        footerTemplate: `   <div style='float: right'>Total</div><br>
                                            <div style='float: right'>Uang Muka</div><br>
                                            <div style='float: right'>PPN `+ data.tax_percentage +`%</div><br>
                                            <div style='float: right'>Invoice Total</div>` },
                    { field: 'price_discount', title: "Total", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}",  editable: false, nullable: true, attributes: { "class": "k-text-right" }, 
                        aggregates: ["sum"],  footerTemplate: ` <div style='float: right'>Rp. #=  kendo.toString((sum), 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ data.total_deposit +`, 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ invoiceData.total_tax_amount +`, 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ invoiceData.total_vat +`, 'n0') #</div>` },
                ],
            });
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');

            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.invoice_id.toString();

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }
            else {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.invoice_id.toString();

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
        changeStatus(status){
            this.statusFilterData = status,
            this.dataSource =  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_invoice\".\"status\"" },
                                { field: "created_at", new_field: "\"transaction_invoice\".\"created_at\"" },
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "total", new_field: "\"transaction_invoice\".\"total\"" },
                                { field: "payment_method", new_field: "\"Project\".project_detail->>'cash'::text" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                                { field: "approved_by", new_field: "\"Approved\".\"myname\"" },
                                { field: "notes", new_field: "\"transaction_invoice\".\"notes\"" },
                                { field: "sales_name", new_field: "\"Project__Sales\".contact_name" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: invoiceServices.readInvoiceQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionInvoice.transaction_invoice == null){
                            return [];
                        }else{
                            return response.data.GetTransactionInvoice.transaction_invoice;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionInvoice.transaction_invoice == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionInvoice.total;
                        }
                    },
                    model: {
                        fields: {
                            invoice_type: {type: "dropdown" },
                            total: {type: "number" },
                            invoice_date: {type: "date"},
                            due_date: {type: "date"},
                            created_at: {type: "date"}
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
        async excel() {
            var grid = this.$refs.grid.kendoWidget().dataSource;
            
            const paging = {
                Filter : globalfunc.filterQueryBuilder(grid._filter),
                OrderBy : globalfunc.sortingQueryBuilder(grid._sort)
            }
            
            var data = await invoiceServices.getDetailInvoiceExcel(this.hf_ID_Filter, this.hf_CheckAll_Filter, paging, this.statusFilterData);
            var sortedData = data.sort((a,b) => (a.invoice_number > b.invoice_number) ? 1 : ((b.invoice_number > a.invoice_number) ? -1 : 0));
            var json = invoiceServices.invoiceExcel(sortedData);
            return json;
        },
    }
}
</script>