<template>
    <div>
        <div class="modal fade" id="InvoiceCreateModal" tabindex="-1" aria-labelledby="InvoiceCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="InvoiceCreateModalLabel" class="font-weight-bold">Add Invoice</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <invoice-create-grid ref="grid" :key="gridReload" :createClick="createClick"/>
                        <br>
                        <CButton type="button" @click="createClick()" id="saveButton" color="success" style="float: right;"> Create </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import invoiceCreateGrid from '../Grid/InvoiceCreateGrid.vue';
import invoiceServices from '../Script/InvoiceServices';

export default {
    name: 'InvoiceCreateForm',
    components: {
        'invoice-create-grid': invoiceCreateGrid,
    },
    mounted:  function () {
    },
    data: function () {
        return {
           gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.reload();
            window.$('#InvoiceCreateModal').modal('show');
        },
        createClick(){
            if (this.$refs.grid.hf_ID_Filter == '') {
                this.$swal("Info", "Harap untuk memilih delivery order sebelum menekan tombol Create", "info");
            }
            else {
                var validate = invoiceServices.validateDuplicateSelectedData(this.$refs.grid.hf_ID_Filter);
                
                if (validate.result) {
                    var selectedId = JSON.stringify(validate.deliveryOrderId);
                    window.$('#InvoiceCreateModal').modal('hide');
                    this.$router.push({ name: 'Invoice Form', params: { formtype:'Add', id:selectedId, view:'new', type:'Invoice', poid:validate.purchaseOrderId } });
                }
                else {
                    this.$swal("Info", "Harap untuk memilih invoice dengan nomor purchase order yang sama", "info");
                }
            }
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>