<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import invoiceServices from '../Script/InvoiceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'InvoiceCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['createClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_form_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging
                                }
                                return { 
                                    query: invoiceServices.readDeliveryOrderQuery(),
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {                      
                        data: function (response) {
                            if (response.data.GetInvoiceableDeliveryOrder == null) {
                                return [];
                            }
                            else {
                                return response.data.GetInvoiceableDeliveryOrder;
                            }
                        },
                        total: function (response) {
                            if (response.data.GetInvoiceableDeliveryOrder == null) {
                                return 0;
                            }
                            else {
                                return response.data.GetInvoiceableDeliveryOrder.length;
                            }
                        },
                        model: {
                            fields: {
                                term_of_payment: { type:"number" }
                            }
                        }
                    },
                    sort: { field: "delivery_order_number", dir: "desc" }
                }),
            columns:  [
                { template: this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { 
                    title: "Informasi DO", width: 400, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    columns: [
                        { field:"delivery_order_number", title: "No. DO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                        { field:"storage_id", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    ]
                },
                { 
                    title: "Informasi PO", width: 400, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    columns: [
                        { field:"purchase_order_number", title: "No. PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                            groupHeaderTemplate: "Nomor PO : #= value #" },
                        { field:"sp_number", title: "No. SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    ]
                },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "project_detail.cash", title: "Tipe Pembayaran", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "term_of_payment", title: "Jatuh Tempo (Hari)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-right" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();

        var GridElement = grid.element;

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.delivery_order_id.toString() + '|' + row.purchase_order_id.toString() + '|' + row.sp_number.toString();
                       
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        headerTemplate() {
            return `&nbsp;`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');
            
            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.delivery_order_id.toString() + '|' + datarow.purchase_order_id.toString() + '|' + datarow.sp_number.toString();

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }
            else {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.delivery_order_id.toString() + '|' + datarow.purchase_order_id.toString() + '|' + datarow.sp_number.toString();

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
</style>
