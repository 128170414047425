<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Invoice : {{InvoiceCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nomor Bukti</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoiceCode" v-model="InvoiceCode" class="font-weight-bold"/>
                            <label id="errorInvoiceCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor DO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="DeliveryOrderNumber" v-model="DeliveryOrderNumber" class="font-weight-bold" readonly/>
                            <label id="errorDeliveryOrderNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="InvoiceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                                @change = "onChangeInvoiceDate"
                            ></date-picker>
                            <label id="errorInvoiceDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Faktur PPN</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="TaxInvoice" v-model="TaxInvoice" class="font-weight-bold" />
                            <label id="errorTaxInvoice" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Customer</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Customer" class="pb-3" :options="CustomerData" v-model="Customer" :disabled="DisabledValue ? false : true" />
                            <label id="errorCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor SP</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="SPNumber" v-model="SPNumber" class="font-weight-bold" readonly/>
                            <label id="errorSPNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PoNumber" v-model="PoNumber" class="font-weight-bold" readonly/>
                            <label id="errorPoNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Diskon Plus (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="DiscountPlus" v-model="DiscountPlus" class="font-weight-bold form-control input-number" @change="onChangeDiscountPlus()" :disabled="this.Type == 'Deposit'"/>
                            <label id="errorDiscountPlus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak PPN (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PPNTax" class="font-weight-bold" :options="PPNTaxData" :value.sync="PPNTax" :disabled="DisabledValue ? false : true" @change="onChangePPNTax()"/>
                            <label id="errorPPNTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Jatuh Tempo</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                :disabled="true"
                                v-model="InvoiceDueDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceDueDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>
                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :pageable="true"
                                        :editable="EditableValue"
                                        :resizable="true"
                                        :cellClose="itemCellClose"
                                        >

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity Dasar'"
                                                    :template="baseQuantityTemplate"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'price_discount_per_unit'"
                                                    :title="'Harga'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'vat_per_unit'"
                                                    :title="'VAT'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'vat'"
                                                    :title="'Total'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    <hr>
                    <div v-if="this.Type == 'Invoice'">
                        <CRow>
                            <div class="col-sm-label">
                            </div>
                            <div class="col-sm-content">
                            </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Remaining Debit</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <input type="number" id="RemainingDeposit" v-model="RemainingDeposit" class="font-weight-bold form-control input-number" readonly/>
                            </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Total Debit</label>&nbsp;
                                <input class="form-label align-middle" type="checkbox" v-model="UseDeposit" @change="onChangeUseDeposit()" v-if="this.FormType == 'Add'"/>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <input type="number" id="TotalDeposit" v-model="TotalDeposit" class="font-weight-bold form-control input-number" @change="countTotalInvoice()"/>
                                <label id="errorTotalDeposit" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div> 
                        </CRow>
                    </div> 

                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalGross" v-model="TotalGross" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>

                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/invoice')"> Close </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" @click="recalculateClick()" id="recalculateButton" color="primary" style="float: right;" v-if="this.UseDeposit"> Recalculate </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import invoiceServices from '../Script/InvoiceServices';
import { response } from '../../../../infrastructure/constant/response';
import moment from 'moment';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'ProjectFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    async mounted () {
        if (this.FormType == 'Add') {
            this.InvoiceCode = 'Auto';

            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.InvoiceDate = dateNow;

            this.TaxInvoice = 'Auto';
            this.CustomerData = await invoiceServices.getCustomerQuery();
            this.Note = '';
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.DiscountPlus = 0;
            this.PPNTaxData = await invoiceServices.getDropdownPPNTax();

            if(this.Type == 'Deposit') {
                this.DisabledValue = true;
                this.EditableValue = true;

                var ppnTax = await globalfunc.getPPNTax();
                this.PPNTax = ppnTax.label;

                this.DeliveryOrderNumber = null;
                this.Customer = '';
                this.PoNumber = null;
                this.SPNumber = null;
                this.InvoiceDueDate = dateNow;

                this.PurchaseOrderId = null;
                this.DeliveryOrderId = null;
                this.ItemGridData = invoiceServices.invoiceDepositItemDataChange(null, this.DiscountPlus, this.PPNTax);
            }
            else {
                this.DisabledValue = false;
                this.EditableValue = false;
                
                //base Data Using PO Data
                var poData = await invoiceServices.getPurchaseOrderQuery(this.PurchaseOrderId);
                var invoiceGridData = await invoiceServices.getInvoiceableItemList(this.DeliveryOrderId);
                
                this.DeliveryOrderNumber = JSON.stringify(invoiceGridData[0].delivery_order_number).replace(/"/g, '').replace(/,/g, ', ').replace('[', '').replace(']', '');
                this.PPNTax = poData.tax_percentage;
                this.Customer = this.CustomerData.find(c => c.value == poData.customer_id);
                this.PoNumber = poData.purchase_order_number;
                this.SPNumber = poData.sp_number;
                this.TermOfPayment = poData.term_of_payment;
                this.InvoiceDueDate = moment(dateNow).add(parseInt(this.TermOfPayment), 'days').format('YYYY-MM-DD');

                //deposit
                this.RemainingDeposit = poData.customer.contact_debit;
                this.TotalDeposit = 0;
                this.UseDeposit = false;
                
                //Grid Item
                this.OldItemGridData = invoiceGridData;
                this.ItemGridData = invoiceServices.invoiceItemDataChange(invoiceGridData, this.DiscountPlus, this.PPNTax, this.FormType);
                this.countTotalInvoice();

                document.getElementById('TotalDeposit').readOnly = true;
            }
            this.SaveType = 'Add';
        }
        else {
            this.EditableValue = false;

            var data = await invoiceServices.getDetailInvoice(this.Id);
            
            this.InvoiceCode = data.invoice_number;
            this.InvoiceDate = data.invoice_date;
            this.TaxInvoice = data.tax_invoice;
            this.CustomerData = await invoiceServices.getCustomerQuery();
            this.Customer = this.CustomerData.find(c => c.value == data.customer_id);

            this.Note = data.notes;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_invoice');
            this.Status = data.status;
            this.DiscountPlus = data.transaction_invoice_detail[0].discount_plus;
            this.PPNTaxData = await invoiceServices.getDropdownPPNTax();
            this.PPNTax = data.tax_percentage;

            this.InvoiceDueDate = data.due_date;
            var itemGridData = this.$globalfunc.objectToArrayConverter(data.transaction_invoice_detail, 'Transaction-Invoice-InvoiceItemDetailEdit');
            
            if (this.Type == 'Deposit') {
                this.DeliveryOrderNumber = null;
                this.SPNumber = null;
                this.PoNumber = null;
                this.PurchaseOrderId = null;
                this.DeliveryOrderId = null;

                this.ItemGridData = invoiceServices.invoiceDepositItemDataChange(itemGridData, this.DiscountPlus, this.PPNTax);
            }
            else {
                var deliveryOrderData = await invoiceServices.deliveryOrderArrayToString(data.transaction_invoice_delivery_order);

                this.DeliveryOrderNumber = JSON.stringify(deliveryOrderData.delivery_order_number).replace(/"/g, '').replace(/,/g, ', ').replace('[', '').replace(']', '');
                this.SPNumber = data.sp_number;
                this.PoNumber = data.purchase_order_number;

                this.PurchaseOrderId = data.purchase_order_id;
                this.DeliveryOrderId = JSON.stringify(deliveryOrderData.delivery_order_id);
                this.TermOfPayment = data.purchase_order.term_of_payment;

                //deposit
                this.RemainingDeposit = data.customer.contact_debit;
                this.TotalDeposit = data.total_deposit;
                this.UseDeposit = false;

                //Grid Item
                this.OldItemGridData = itemGridData;
                this.ItemGridData = invoiceServices.invoiceItemDataChange(itemGridData, this.DiscountPlus, this.PPNTax, this.FormType);

                this.countTotalInvoice();

                document.getElementById('TotalDeposit').readOnly = true;
            }

            document.getElementById('DiscountPlus').readOnly = true;
            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
            
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Type: this.$route.params.type,
            Error : 0,

            //Detail
            DeliveryOrderId: this.$route.params.id,
            PurchaseOrderId: this.$route.params.poid,
            InvoiceCode: '',
            DeliveryOrderNumber: '',
            InvoiceDate: '',
            InvoiceDueDate: '',
            TermOfPayment: 0,
            TaxInvoice: '',
            CustomerData: [],
            Customer: '',
            DisabledValue: false,
            
            SPNumber: '',
            PoNumber: '',
            Note: '',
            Status: '',
            StatusData: [],
            DiscountPlus: 0,
            PPNTaxData: [],
            PPNTax: 0,

            SaveType: '',
            EditableValue: false,

            //grid
            OldItemGridData: [],
            ItemGridData: [],
            ItemSchemaModel: {
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                base_uom: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                quantity: { type: "number", editable: false },
                price_per_unit: { type: "number", editable: true, validation: { min:0} },
                price_discount_per_unit: { type: "number", editable: true, validation: { min:0} },
                vat_per_unit: { type: "number", editable: false },
                vat: { type: "number", editable: false },
                vat_rd: { type: "number", editable: false },
            },

            //deposit
            UseDeposit: false,
            RemainingDeposit: 0,
            TotalDeposit: 0,
            TotalGross: 0,
            Recalculate: 0,
        }
    },
    methods: {
        baseQuantityTemplate(e){
            var baseQuantity = e.quantity * e.conv_amount;
            return Number(baseQuantity.toFixed(10));
        },
        onChangeInvoiceDate(){
            var dueDate = moment(this.InvoiceDate).add(parseInt(this.TermOfPayment), 'days')
            this.InvoiceDueDate = moment(dueDate).format('YYYY-MM-DD');
        },
        async onChangeDiscountPlus(){
            this.DiscountPlus = this.$globalfunc.percentValidate(this.DiscountPlus);
            
            if(this.Type == 'Deposit'){
                var data = this.$refs.gridItem.kendoWidget().dataSource._data;
                var newItemGridData = invoiceServices.invoiceDepositItemDataChange(data, this.DiscountPlus, this.PPNTax);
                this.ItemGridData = newItemGridData;
            }
            else{
                var newItemGridData = invoiceServices.invoiceItemDataChange(this.OldItemGridData, this.DiscountPlus, this.PPNTax, this.FormType);
                this.ItemGridData = newItemGridData;
            }
            this.countTotalInvoice();
        },
        onChangePPNTax(){
            if(this.Type == 'Deposit'){
                var data = this.$refs.gridItem.kendoWidget().dataSource._data;
                var newItemGridData = invoiceServices.invoiceDepositItemDataChange(data, this.DiscountPlus, this.PPNTax);
                this.ItemGridData = newItemGridData;
            }
        },
        onChangeUseDeposit(){
            this.TotalDeposit = 0;
            if(this.UseDeposit){
                document.getElementById('TotalDeposit').readOnly = false;
            }else{
                document.getElementById('TotalDeposit').readOnly = true;
            }
            this.countTotalInvoice();
        },
        countTotalInvoice(){
            var array = this.ItemGridData;
            var totalGross = 0;

            if(array.length > 0){
                array.forEach(e => {
                    totalGross = totalGross + (e.vat);
                });
            }

            this.TotalGross = Math.round(totalGross);
            this.Recalculate++;
        },
        itemCellClose(e){
            if(this.Type == 'Deposit'){
                var data = this.$refs.gridItem.kendoWidget().dataSource._data;
                data[0].price_per_unit = data[0].price_discount_per_unit;
                var newItemGridData = invoiceServices.invoiceDepositItemDataChange(data, this.DiscountPlus, this.PPNTax);
                this.ItemGridData = newItemGridData;
                this.countTotalInvoice();
            }
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.InvoiceCode == '' || this.InvoiceCode == null){
                this.errorShow('errorInvoiceCode');
            }
            if(this.InvoiceDate == '' || this.InvoiceDate == null){
                this.errorShow('errorInvoiceDate');
            }
            if(this.TaxInvoice == '' || this.TaxInvoice == null){
                this.TaxInvoice = null;
            }
            if(this.UseDeposit == true){
                if(this.TotalDeposit > this.RemainingDeposit){
                    this.$swal("Error", "Total debit tidak boleh melebihi remaining debit", "error");
                    this.Error++;
                }
            }
            if(this.Customer == '' || this.Customer == null){
                this.errorShow('errorCustomer');
            }
            if(this.ItemGridData.length == 0){
                this.$swal("Error", "Item grid tidak boleh kosong", "error");
                this.Error++;
            }

            if(this.Type == 'Deposit'){
                if(this.TotalGross <= 0){
                    this.$swal("Error", "Total deposit tidak boleh 0", "error");
                    this.Error++;
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async recalculateClick(){
            var data = await invoiceServices.getRecalculateQuery(this.setInvoiceData());
            if(data == "Error"){
                this.$swal("Error", "Total deposit lebih besar total invoice", "error");
            }else{
                if(data == null){
                    this.$swal("Error", "Recalculate error", "error");
                }else{
                    this.ItemGridData = data;
                    this.countTotalInvoice();
                }
            }
            this.Recalculate = 0;
        },
        setInvoiceData(){   
            var invoiceType = 1;
            var deliveryOrderId = null;
            if(this.Type == 'Deposit'){
                invoiceType = 2;
            }else{
                deliveryOrderId = JSON.parse(this.DeliveryOrderId);
            }

            //Count RoundDown
            var array = this.ItemGridData;
            var totalRD = 0;

            if(array.length > 0){
                array.forEach(e => {
                    totalRD = totalRD + e.vat_rd;
                });
            }
            
            //Status Delete Invoice_Number null
            var status = this.Status;
            if(status == "Delete") {
                this.InvoiceCode = null;
                this.TaxInvoice = null;
            }

            const invoiceData = {
                invoice_number: this.InvoiceCode,
                invoice_type: invoiceType,
                invoice_date: moment(this.InvoiceDate).format("YYYY-MM-DD"),
                due_date: moment(this.InvoiceDueDate).format("YYYY-MM-DD"),
                status: this.Status,
                purchase_order_id: this.PurchaseOrderId,
                delivery_order_id: deliveryOrderId,
                customer_id: this.Customer.value,
                tax_invoice: this.TaxInvoice,
                tax_percentage: this.PPNTax,
                tax_invoice_deposit: this.UseDeposit == true ? 'Auto' : null,
                total: this.TotalGross,
                total_deposit: parseFloat(this.TotalDeposit),
                notes: this.Note,
                total_rd: totalRD,
                transaction_invoice_detail: this.$globalfunc.objectToArrayConverter(this.ItemGridData, "Transaction-Invoice-InvoiceItemDetailSave")
            }

            return invoiceData;
        },
        saveClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    if(this.Type == 'Invoice' && this.UseDeposit){
                        this.recalculateClick();
                    };

                    const variables = {
                        data : this.setInvoiceData()
                    };
                    
                    invoiceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/invoice' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : this.setInvoiceData()
                    };

                    invoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/invoice'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
            }
        }
    } 
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>